import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../helpers/context';

import Template from '../templates/home';

const IndexPage = ({ data }) => {

  const { lead, text, seoTitle, seoDescription, seoImage, homeCanvasItems, goToLabel } = data.med.pageHome;

  const contact = data.med.pageContact;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          lead={lead}
          text={text}
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={seoImage}
          contextData={data}
          setContext={set}
          langTo={'/en'}
          contact={contact}
          homeCanvasItems={homeCanvasItems}
          goToLabel={goToLabel}
          projectsLink={'/works'}
        />
      )}
    </ContextConsumer>
  );
};

export const pageHomeQuery = graphql`
  {
    med {
      pageHome(where: { id: "ckjtsrpy826es0946ime6m772" }, locales: pl_PL) {
        lead {
          html
          markdown
          raw
        }
        text {
          html
          markdown
          raw
        }
        goToLabel
        homeCanvasItems {
          name
          file {
            url(
              transformation: {
                image: { resize: { width: 1000 } }
              }
            )
            mimeType
          }
          project {
            slug
          }
          videoUrl
        }
        seoTitle
        seoDescription
        seoImage {
          url
        }
      }
      pageContact(where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }, locales: pl_PL) {
        heading1
        email
        heading2
        address
        mapsUrl
      }
    }
  }
`;

export default IndexPage;
